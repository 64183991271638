import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  getproductbykeyword,
  sortApiDataByPrice,
  pageId,
  sortMessage,
} from "../redux/actions/users";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./loader";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const apiUrl = process.env.REACT_APP_SERVER_BASE_URI;
const keywordApiRoute = "getItemByKeyword?items_name";
const barcodeApiRoute = "getActiveListingByBarcode?barcodeNumber";

const Sortfiltericon = (props) => {
  let dispatch = useDispatch();
  const message = useSelector((state) => state.getsortMessage);
  const [isloading, setIsloading] = useState(false);
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const priceRange = useSelector((state) => state.rangeReducer);
  const sortStatus = useSelector((state) => state.sortReducer);
  let { Product_name, bar_code } = useParams();

  function roundUpToNearestMultiple(number, multiple) {
    return Math.ceil(number / multiple) * multiple;
  }

  let sortByPrice = async () => {
    setIsloading(true);
    dispatch(pageId(1));
    setTimeout(() => {
      setIsloading(false);
    }, 5000);
  };

  return (
    <>
      {!isloading ? (
        <div>
          <div className="option-wrapper">
            <Dropdown drop="end" className="dropdown">
              <DropdownToggle
                className="btn p-0 sort-btn dropdown-toggle"
                variant=""
              >
                <span>Sort</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.667"
                  height="13.663"
                  viewBox="0 0 10.667 13.663"
                >
                  <g id="sorticon" transform="translate(-63 -13.5)">
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.523,15.507l4.033-4.036a.759.759,0,0,1,1.077,0,.769.769,0,0,1,0,1.08l-4.57,4.573a.761.761,0,0,1-1.051.022l-4.6-4.592a.762.762,0,1,1,1.077-1.08Z"
                      transform="translate(56.813 9.817)"
                      fill="#505058"
                    />
                    <path
                      id="Icon_ionic-ios-arrow-down-2"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.523,13.085l4.033,4.036a.759.759,0,0,0,1.077,0,.769.769,0,0,0,0-1.08l-4.57-4.573a.761.761,0,0,0-1.051-.022l-4.6,4.592a.762.762,0,1,0,1.077,1.08Z"
                      transform="translate(56.813 2.253)"
                      fill="#505058"
                    />
                  </g>
                </svg>

                {sortStatus.sort == null ? (
                  ""
                ) : sortStatus.sort === "price" ? (
                  <span>&nbsp;{"Low to high Price"}</span>
                ) : sortStatus.sort === "-price" ? (
                  <span>&nbsp;{"High to Low Price"}</span>
                ) : sortStatus.sort === "newlyListed" ? (
                  <span>&nbsp;{"Newest Listing"}</span>
                ) : sortStatus.sort === "endingSoonest" ? (
                  <span>&nbsp;{"Oldest Listing"}</span>
                ) : (
                  ""
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    // console.log("clicked l to h");
                    dispatch(sortApiDataByPrice("price"));
                    dispatch(sortMessage("price"));
                  }}
                >
                  Low to high Price
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    // console.log("clicked h to l");
                    dispatch(sortApiDataByPrice("-price"));
                    dispatch(sortMessage("-price"));
                    // sortByPrice("-price");
                  }}
                >
                  High to Low Price
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    // console.log("clicked new listings");
                    dispatch(sortApiDataByPrice("newlyListed"));
                    dispatch(sortMessage("newlyListed"));
                    // sortByPrice("newlyListed");
                  }}
                >
                  Newest Listing
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    dispatch(sortApiDataByPrice("endingSoonest"));
                    dispatch(sortMessage("endingSoonest"));
                    // sortByPrice("endingSoonest");
                  }}
                >
                  Oldest Listing
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    dispatch(sortApiDataByPrice(null));
                    dispatch(sortMessage(""));
                    // sortByPrice(null);
                  }}
                >
                  Reset
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            {/* <button className="btn p-0 sort-btn dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false"><span>Sort</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10.667" height="13.663" viewBox="0 0 10.667 13.663"><g id="sorticon" transform="translate(-63 -13.5)">
                            <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M11.523,15.507l4.033-4.036a.759.759,0,0,1,1.077,0,.769.769,0,0,1,0,1.08l-4.57,4.573a.761.761,0,0,1-1.051.022l-4.6-4.592a.762.762,0,1,1,1.077-1.08Z" transform="translate(56.813 9.817)" fill="#505058" />
                            <path id="Icon_ionic-ios-arrow-down-2" data-name="Icon ionic-ios-arrow-down" d="M11.523,13.085l4.033,4.036a.759.759,0,0,0,1.077,0,.769.769,0,0,0,0-1.08l-4.57-4.573a.761.761,0,0,0-1.051-.022l-4.6,4.592a.762.762,0,1,0,1.077,1.08Z" transform="translate(56.813 2.253)" fill="#505058" />
                            </g></svg>

                        {message.response == null ? '' : message.response === 'price' ? <span>&nbsp;{"Low to high Price"}</span> : message.response === '-price' ? <span>&nbsp;{"High to Low Price"}</span> : message.response === 'newlyListed' ? <span>&nbsp;{"Newest Listing"}</span> : message.response === 'endingSoonest' ? <span>&nbsp;{"Oldest Listing"}</span> : ''}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end ">
                        <li><span className="dropdown-item" onClick={() => {sortByPrice('price'); dispatch(sortApiDataByPrice('price')); dispatch(sortMessage('price'))}} type="button">Low to high Price</span></li>
                        <li><span className="dropdown-item" onClick={() => {sortByPrice('-price'); dispatch(sortApiDataByPrice('-price')); dispatch(sortMessage('-price'))}} type="button">High to Low Price</span></li>
                        <li><span className="dropdown-item" onClick={() => {sortByPrice('newlyListed'); dispatch(sortApiDataByPrice('newlyListed')); dispatch(sortMessage('newlyListed'))}} type="button">Newest Listing</span></li>
                        <li><span className="dropdown-item" onClick={() => {sortByPrice('endingSoonest'); dispatch(sortApiDataByPrice('endingSoonest')); dispatch(sortMessage('endingSoonest')) }} type="button">Oldest Listing</span></li>
                        <li><span className="dropdown-item" onClick={() => {sortByPrice(null); dispatch(sortApiDataByPrice(null)); dispatch(sortMessage(''))}} type="button">Reset</span></li>
                    </ul> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default Sortfiltericon;
